export default function() {
  const readMoreBtn = document.querySelector('.read-more');
  const paragraphs = document.querySelectorAll('.modal-body__text p');

  paragraphs.forEach((paragraph, index) => {
    if (index != 0) {
      paragraph.classList.add('hide-in-mobile');
    }
  });

  if (readMoreBtn) {
    readMoreBtn.addEventListener('click', (e) => {
      e.preventDefault();
      readMoreBtn.classList.remove('read-more-icon');
      readMoreBtn.classList.remove('read-less-icon');
      const readMoreText = readMoreBtn.getAttribute('data-message-readmore').trim();
      const readLessText = readMoreBtn.getAttribute('data-message-readless').trim();
      if (readMoreBtn.children[0].innerText.trim() === readMoreText) {
        readMoreBtn.children[0].innerText = readLessText;
        readMoreBtn.classList.add('read-less-icon');

        paragraphs.forEach((paragraph, index) => {
          if (index != 0) {
            paragraph.classList.add('show-in-mobile');
            paragraph.classList.remove('hide-in-mobile');
          }
        });

      } else {
        readMoreBtn.children[0].innerText = readMoreText;
        readMoreBtn.classList.add('read-more-icon');

        paragraphs.forEach((paragraph, index) => {
          if (index != 0) {
            paragraph.classList.remove('show-in-mobile');
            paragraph.classList.add('hide-in-mobile');
          }
        });
      }
    })
  }
}

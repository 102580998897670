const StepType = {
  SELECT_AGE: 0,
  SELECT_DATE: 1,
  SELECT_HOUR: 2,
  SELECT_SERVICE: 3,
};
const StepStatus = {
  PENDING: 0,
  VALIDATED: 1,
};
const TicketData = {
  Quantities: [],
  Date: false,
  Time: false,
  Departure: false,
  Details: [],
  Products: [],
  UseCoupon: false,
  Completed: false,
};
const loadingIcon = () => {
  return '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_b2T7{animation:spinner_xe7Q .8s linear infinite}.spinner_YRVV{animation-delay:-.65s}.spinner_c9oY{animation-delay:-.5s}@keyframes spinner_xe7Q{93.75%,100%{r:3px}46.875%{r:.2px}}</style><circle class="spinner_b2T7" cx="4" cy="12" r="3"/><circle class="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3"/><circle class="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3"/></svg>';
};
const feather = require('feather-icons');
const StepsSwitcher = () => {
  const activeClass = 'active';
  const EnableStep = (stepIndex) => {
    if (validateSteps(false)) {
      steps[stepIndex].classList.remove('disabled');
    }
  };
  const DisableStep = (stepIndex) => {
    if (validateSteps(false)) {
      steps[stepIndex].classList.add('disabled');
    }
  };
  let prevStep = -1;
  let currentStep = -1;
  const steps = document.querySelectorAll('.step-wrapper');
  const stepContinues = document.querySelectorAll('.step-continue');
  let quantitySelected = 0;
  let initCalendarValidation = false;
  let agesQuantities = [];
  let selectedSeason = false;
  const Toggle = (step, index) => {
    const isDisabled = steps[index].classList.contains('disabled');
    if (isDisabled) { { return; } }
    if (prevStep !== currentStep) {
      prevStep = index;
    }
    if (currentStep === index) { return; } // Skip enable same tab every time, this is not funny
    currentStep = index;
    ActiveTab(index);
  };
  const RecheckStep = (stepType, prevInputs, nextInputs) => {
    let differentInputs = false;
    if (stepType === StepType.SELECT_AGE) {
      differentInputs = isDifferent(prevInputs, nextInputs);
      //refreshAllSummary();
    }
    if (stepType === StepType.SELECT_DATE) {
      differentInputs = compareDates(prevInputs, nextInputs);
    }
    if (differentInputs) {
      steps.forEach((step, index) => {
        if (index > stepType && step.getAttribute('data-valid')) {
          step.setAttribute('data-valid', false);
          DisableStep(index);
          DisableTabs(stepType);
          TicketData.Date = false;
          TicketData.Time = false;
          TicketData.Departure = false;
          TicketData.Details = [];
        }
      });
    }
  };
  const ActiveTab = (index) => {
    const link = steps[index];
    for (let i = 0; i < steps.length; i++) {
      const info = steps[i].querySelector('.info-body');
      if (i !== index) {
        steps[i].classList.remove(activeClass);
        info.style.height = 0;
      } else {
        link.classList.add(activeClass);
        setStepHeight(currentStep);
        const changeOptEl = steps[i].querySelector('.modifier');
        changeOptEl.classList.remove('d-none');
      }
    }
  };
  const DisableTabs = (excludeStepIndex) => {
    for (let i = 0; i < steps.length; i++) {
      if (i !== excludeStepIndex) {
        const info = steps[i].querySelector('.info-body');
        steps[i].classList.remove(activeClass);
        if (i > excludeStepIndex) {
          steps[i].classList.add('disabled');
        }
        info.style.height = 0;
      }
    }
  };
  const setStepHeight = (stepIndex, setAutoAfterTransition = true) => {
    const tab = getStepElement(stepIndex);
    const infoBody = tab.querySelector('.info-body');
    const infoHeight = infoBody.scrollHeight;
    infoBody.style.height = infoHeight + 'px';
    const durationInCss = parseInt(window.getComputedStyle(infoBody).transitionDuration);
    // Set the height in auto after transition
    if (setAutoAfterTransition) {
      setTimeout(() => {
        infoBody.style.height = 'auto';
      }, durationInCss*1000);
    }
  };
  // Product manage
  const productElements = document.querySelectorAll('.complements-shop .shop-article');
  productElements.forEach((el) => {
    el.querySelector('.quantity-increase').addEventListener('click', () => {
      const currentProductSize = el.querySelector('.sizes-item.active');
      const mobileCurrentProductSize = el.querySelector('.options-sizes-list-mobile option:checked');
      if (currentProductSize || mobileCurrentProductSize) {
        const quantityNumberEl = el.querySelector('.quantity-number');
        let newQ = parseInt(quantityNumberEl.innerHTML)+1;
        if (newQ > 99) {
          newQ = 99;
        }
        quantityNumberEl.innerText = newQ+'';
        if (newQ > 0) {
          AddUpdateCurrentProductToSummary();
        }
      }
    });
    el.querySelector('.quantity-decrease').addEventListener('click', () => {
      const currentProductSize = el.querySelector('.sizes-item.active');
      const mobileCurrentProductSize = el.querySelector('.options-sizes-list-mobile option:checked');
      if (currentProductSize || mobileCurrentProductSize) {
        const quantityNumberEl = el.querySelector('.quantity-number');
        let newQ = parseInt(quantityNumberEl.innerHTML)-1;
        if (newQ < 0) {
          newQ = 0;
        }
        quantityNumberEl.innerText = newQ+'';
        if (newQ === 0) {
          RemoveCurrentProductFromSummary();
        } else {
          AddUpdateCurrentProductToSummary();
        }
      }
    });
    // Selection size (Desktop)
    el.querySelectorAll('.sizes-item').forEach((sizeItemEvent) => {
      sizeItemEvent.addEventListener('click', (e) => {
        if (e.target.classList.contains('disabled')) return;
        el.querySelectorAll('.sizes-item').forEach((x) => {x.classList.remove('active');});
        const sizeItemEl = e.target;
        sizeItemEl.classList.add('active');
        const price = sizeItemEl.getAttribute('data-price');
        const finalPrice = sizeItemEl.getAttribute('data-final-price');
        el.querySelector('.article-price-quantity .article-price .price-numbers').innerHTML = price;
        el.querySelector('.article-price-quantity .article-price-final .price-numbers').innerHTML = finalPrice;
        const quantityNumberEl = el.querySelector('.quantity-number');
        quantityNumberEl.innerText = sizeItemEl.getAttribute('data-quantity');
      });
    });
    // Selection size (Mobile)
    el.querySelector('.options-sizes-list-mobile').addEventListener('change', (e) => {
      const selectedSizes = e.target.selectedOptions;
      if (selectedSizes) {
        const price = selectedSizes[0].getAttribute('data-price');
        const finalPrice = selectedSizes[0].getAttribute('data-final-price');
        const sizeItemForActive = el.querySelectorAll('.sizes-item')[e.target.selectedIndex];
        el.querySelectorAll('.sizes-item').forEach((x) => {x.classList.remove('active');});
        if (sizeItemForActive) {
          sizeItemForActive.classList.add('active');
        }
        el.querySelector('.article-price-quantity .article-price .price-numbers').innerHTML = price;
        el.querySelector('.article-price-quantity .article-price-final .price-numbers').innerHTML = finalPrice;
        const quantityNumberEl = el.querySelector('.quantity-number');
        const currentProductSize = el.querySelector('.sizes-item.active');
        quantityNumberEl.innerText = currentProductSize.getAttribute('data-quantity');
      }
    });
    function AddUpdateCurrentProductToSummary() {
      const currentProductSize = el.querySelector('.sizes-item.active');
      const quantityNumberEl = el.querySelector('.quantity-number');
      currentProductSize.setAttribute('data-quantity', quantityNumberEl.innerHTML);
      const addedToCartMsgEl = document.querySelector('.added-to-cart');
      if (addedToCartMsgEl) {
        addedToCartMsgEl.classList.add('show');
        setTimeout(() => {
          addedToCartMsgEl.classList.remove('show');
          }, 3000);
      }
    }
    function RemoveCurrentProductFromSummary() {
      const currentProductSize = el.querySelector('.sizes-item.active');
      const quantityNumberEl = el.querySelector('.quantity-number');
      quantityNumberEl.innerHTML = '0';
      currentProductSize.setAttribute('data-quantity', '0');
    }
  });
  const refreshSummaryProducts = (fillTicket = false) => {
    const productElements = document.querySelectorAll('.complements-shop .shop-article');
    const elementWrapper = document.querySelector('.products-wrapper');
    if (elementWrapper) {
      const productsDiscountsSummaryObjs = [];
      elementWrapper.innerHTML = '';
      let contentProductsHtml = '';
      let numProductsAdded = 0;
      productElements.forEach((el) => {
        const productActiveSizeEls = el.querySelectorAll('.sizes-item');
        let quantityProductSizes = productActiveSizeEls.length;
        productActiveSizeEls.forEach((productActiveSizeEl) => {
          const q = parseInt(productActiveSizeEl.getAttribute('data-quantity'));
          const lineTotalPrice = parseFloat(productActiveSizeEl.getAttribute('data-final-price').replace(',', '.')) * q;
          let pSizeDetails = `(${productActiveSizeEl.innerText})`;
          if (quantityProductSizes <= 1) {
            pSizeDetails = '';
          }
          if (q > 0) {
            contentProductsHtml += `
            <div class="d-flex justify-content-between content-products">
            <p>
              <span class="number-item">${productActiveSizeEl.getAttribute('data-quantity')}</span> x <span class="item-name">${productActiveSizeEl.getAttribute('data-product-name')} ${pSizeDetails}</span> <span
            class="single-price-item">${productActiveSizeEl.getAttribute('data-final-price')}€</span>
            </p>
             <p>
                <span class="total-price-item">${lineTotalPrice.toFixed(2).replace('.', ',')}€</span>
             </p>
             </div>
          `;
            const productDiscountValue = parseFloat(productActiveSizeEl.getAttribute('data-product-discount'));
            if (productDiscountValue > 0) {
              productsDiscountsSummaryObjs.push({ProductName: `${productActiveSizeEl.getAttribute('data-product-name')} (${productActiveSizeEl.innerText})`, DiscountValue: productDiscountValue, DiscountType: parseInt(productActiveSizeEl.getAttribute('data-product-discount-type')), Quantity: parseInt(productActiveSizeEl.getAttribute('data-quantity')) });
            }
            numProductsAdded++;
            if (fillTicket) {
              TicketData.Products.push({ProductSizeId: parseInt(productActiveSizeEl.getAttribute('data-product-size-id')), ProductName: productActiveSizeEl.getAttribute('data-product-name'), Quantity: parseInt(productActiveSizeEl.getAttribute('data-quantity'))});
            }
          }
        });
      });
      if (numProductsAdded > 0) {
        document.querySelectorAll('.products-info-element').forEach((x) => {
          x.classList.remove('d-none');
        });
        elementWrapper.innerHTML = contentProductsHtml;
      } else {
        document.querySelectorAll('.products-info-element').forEach((x) => {
          x.classList.add('d-none');
        });
      }
      // Show discounts without need a request to api (only client side)
      const enableShowProductDiscounts = false;
      if (enableShowProductDiscounts) {
        const productDiscountsWrapper = document.querySelector('#summary-aside-wrapper .products-discounts-wrapper');
        if (productsDiscountsSummaryObjs && productsDiscountsSummaryObjs.length > 0) {
          productDiscountsWrapper.classList.remove('d-none');
          let htmlProductDiscounts = '';
          const productDiscountsLbl = productDiscountsWrapper.getAttribute('data-discount-label');
          productsDiscountsSummaryObjs.forEach((x) => {
            if (x.DiscountType === 0) {
              x.DiscountValue *= x.Quantity;
            }
            htmlProductDiscounts += `
            <div class="d-flex justify-content-between align-items-center">
              <p class="m-0">
                <span class="item-name">${x.ProductName} - ${productDiscountsLbl}</span>
              </p>
              <p class="m-0">
                <span class="total-price-item">-${x.DiscountValue + (x.DiscountType === 0 ? '€': '%')}</span>
              </p>
            </div>
            `;
          });
          productDiscountsWrapper.innerHTML = htmlProductDiscounts;
        } else {
          productDiscountsWrapper.classList.add('d-none');
        }
      }
    }
  };
  // Modals
  const toggleShowModals = document.querySelectorAll('.card-close-icon');
  const modalDetailsLaunchers = document.querySelectorAll('.modal-details-launcher');
  const modal = document.querySelector('.card-aside');
  if (toggleShowModals) {
    toggleShowModals.forEach((el) => {
      el.addEventListener('click', openCloseModal);
    });
  }
  if (modalDetailsLaunchers) {
    modalDetailsLaunchers.forEach((el) => {
      el.addEventListener('click', openCloseModal);
    });
  }
  function openCloseModal () {
    modal.style.display === 'flex' ? modal.style.display = 'none' : modal.style.display = 'flex'
  }
  const SwitchStep = (index, ajaxRequest = true) => {
    let valid = true;
    if (index > currentStep) {
      valid = validateSteps(ajaxRequest);
      fillTicketDetails();
    }
    if (valid) {
      Toggle(steps, index);
    }
    window.preventCalendarInit = !valid;
    LockContinue = false;
  };
  steps.forEach(function (step, index) {
    const btnContinues = step.querySelectorAll('.modifier');
    btnContinues.forEach((el) => {
      el.classList.add('d-none');
    });
    if (step.classList.contains(activeClass)) {
      setTimeout(() => {
        Toggle(steps, index);
      }, 250);
    }
    step.addEventListener('click', (e) => {
      e.stopPropagation();
      SwitchStep(index);
    });
  });
  let LockContinue = false;
  stepContinues.forEach((domEl) => {
    domEl.addEventListener('click', (e) => {
      if (!LockContinue) {
        LockContinue = true;
        e.stopPropagation();
        const stepIndex = domEl.closest('.step-wrapper').getAttribute('data-step-index');
        const valid = validateSteps(false);
        if (valid) {
          const nextStep = parseInt(stepIndex)+1;
          setTimeout(() => { EnableStep(nextStep); SwitchStep(nextStep, true ); refreshSubmitBtn(true); }, 1000);
        } else {
          refreshSubmitBtn(false);
        }
      }
    });
  });
  // Export to window object and expose publicly
  window.setStepHeight = setStepHeight;
  const getStepElement = (stepIndex) => {
    return steps[stepIndex];
  };
  const refreshStepDetails = (stepIndex, text) => {
    const stepDetails = getStepElement(stepIndex).querySelectorAll('.details');
    stepDetails.forEach((el) => {
      if (el) {
        el.textContent = text;
      }
    });
  };
  const refreshWarning = (stepIndex, text) => {
    const warningEl = getStepElement(stepIndex).querySelector('.step-warning');
    warningEl.textContent = text;
  };
  const getSummaryWrapperElement = () => {
    return document.querySelector('#summary-aside-wrapper');
  };
  const getActivityPrices = () => {
    const prices = JSON.parse(atob(getSummaryWrapperElement().getAttribute('data-prices')));
    return prices.promo.concat(prices.blue).concat(prices.green);
  };
  const getActivityPrice = (AgeId, ServiceId) => {
    const prices = getActivityPrices();
    let price = 0;
    const pricesFiltered = prices.filter((x) => { return x.service_id === ServiceId && x.age_id === AgeId && x.season === selectedSeason });
    if (pricesFiltered.length > 0) {
      price = pricesFiltered[0].price;
    }
    return price;
  };
  const refreshSummaryDate = (dateFormatted, ajaxRequest = true) => {
    const summaryWrapperEl = getSummaryWrapperElement();
    summaryWrapperEl.querySelector('.date .complete-date').textContent = dateFormatted;
    const d = window.calendar.getDate();
    const monthN = (d.getMonth() + 1).toString().padStart(2, '0');
    const dayN = d.getDate().toString().padStart(2, '0');
    if (ajaxRequest) {
      // eslint-disable-next-line no-undef
      setActivityHours(dayN + '-' + monthN  + '-' +  d.getFullYear());
    }
  };
  const getDateYmd = (date = new Date()) => {
    const now = date;
    const nowM = (now.getMonth() + 1).toString().padStart(2, '0');
    const nowD = now.getDate().toString().padStart(2, '0');
    const nowY = now.getFullYear();
    const dateYmd = nowY + '-' + nowM + '-' + nowD;
    return dateYmd;
  };
  const getSeasonByDate = (date = 'now') => {
    if (date === 'now') {
      date = getDateYmd();
    }
    let season = false;
    const dayElement = document.querySelector('#calendar .fc-day[data-date="'+date+'"] .fc-event');
    if (!dayElement) return season;
    if (dayElement.classList.contains('promo')) {
      season = 'promo';
    }
    if (dayElement.classList.contains('blue')) {
      season = 'blue';
    }
    if (dayElement.classList.contains('green')) {
      season = 'green';
    }
    return season;
  };
  const refreshSummaryTime = (time) => {
    const summaryWrapperEl = getSummaryWrapperElement();
    summaryWrapperEl.querySelector('.schedule .hour').textContent = time;
  };
  const isDifferent = (itemA, itemB) => {
    return JSON.stringify(itemA) !== JSON.stringify(itemB);
  };
  const compareDates = (d1, d2) => {
    let different = false;
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();
    if (date1 < date2) {
      different = true;
    } else if (date1 > date2) {
      different = true;
    }
    return different;
  };
  let prevDateSelected = getDateYmd();
  // This function validate every "Step" (Steps 1-3)
  const validateSteps = (ajaxRequest = true) => {
    let ajaxRequestDate = currentStep === StepType.SELECT_DATE;
    if (!ajaxRequest) {
      ajaxRequestDate = ajaxRequest;
    }
    //Init validation for dateClick calendar event
    if (!initCalendarValidation) {
      initCalendarValidation = true;
      let dateFormat = new Intl.DateTimeFormat('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      let dateFormatted = dateFormat.format(new Date());
      refreshStepDetails(StepType.SELECT_DATE, dateFormatted);
      refreshSummaryDate(dateFormatted, ajaxRequestDate);
      selectedSeason = getSeasonByDate(getDateYmd(new Date()));
      window.calendar.on('dateClick', (info) => {
        let dateFormat = new Intl.DateTimeFormat('es-ES', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
        let dateFormatted = dateFormat.format(info.date);
        TicketData.Date = dateFormatted;
        refreshStepDetails(StepType.SELECT_DATE, dateFormatted);
        ajaxRequest = false;
        refreshSummaryDate(dateFormatted, ajaxRequest);
        selectedSeason = getSeasonByDate(getDateYmd(info.date));
        const nextDate = getDateYmd(window.calendar.getDate());
        RecheckStep(StepType.SELECT_DATE, prevDateSelected, nextDate);
        prevDateSelected = getDateYmd(window.calendar.getDate()); // Update the prev value for next checks
      });
      prevDateSelected = getDateYmd(window.calendar.getDate()); // Update the prev value for next checks
    } else {
      let dateFormat = new Intl.DateTimeFormat('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      let dateFormatted = dateFormat.format(window.calendar.getDate());
      refreshStepDetails(StepType.SELECT_DATE, dateFormatted);
      refreshSummaryDate(dateFormatted, ajaxRequestDate);
      prevDateSelected = getDateYmd(window.calendar.getDate()); // Update the prev value for next checks
    }
    // Do the validations
    const indexAgesStep = StepType.SELECT_AGE;
    const indexDateStep = StepType.SELECT_DATE;
    const indexHourStep = StepType.SELECT_HOUR;
    const indexServiceStep = StepType.SELECT_SERVICE;
    quantitySelected = 0;
    let AgesQuantities = [];
    const qInputs = document.querySelectorAll('input.quantity');
    let noDependents = 0;
    let dependents = 0;
    qInputs.forEach((el) => {
      if (el.getAttribute('data-dependent') === '0') { noDependents += parseInt(el.value); } else { dependents += parseInt(el.value); }
      const ageName = el.closest('.type-row').querySelector('.person-wrapper .person').textContent;
      const ageId = parseInt(el.closest('.type-row').querySelector('.person-wrapper .person').getAttribute('data-age-id'));
      if (el.value > 0) {
        AgesQuantities.push({'ageName': ageName, 'quantity': el.value, 'ageId': ageId });
        quantitySelected+= parseInt(el.value);
      }
    });
    const prevInputs = agesQuantities;
    agesQuantities = AgesQuantities;
    const nextInputs = agesQuantities;
    let validStep1 = true;
    let validStep2 = true;
    let validStep3 = true;
    let validStep4 = true;
    // Validate Step 1
    if (currentStep === indexAgesStep) {
      validStep1 = quantitySelected > 0;
      if (dependents > 0 && noDependents <= 0) {
        validStep1 = false;
        console.log('invalid selection of ages');
      }
      if (window.LimitPaxReached) {
        validStep1 = false;
      }
      let selectedStr = '';
      let ageIteratorIndex = 0;
      AgesQuantities.forEach((age) => {
        if (age.quantity > 0) {
          let sufixAge = '';
          if (age.quantity > 1) {
            sufixAge = 's';
          }
          if (ageIteratorIndex === AgesQuantities.length-1) {
            selectedStr += age.quantity + ' ' + age.ageName + sufixAge;
          } else {
            selectedStr += age.quantity + ' ' + age.ageName + sufixAge + ' / ';
          }
        }
        ageIteratorIndex++;
      });
      refreshStepDetails(currentStep, selectedStr);
      TicketData.Quantities = agesQuantities;
      const stepWarningWrapperElement = steps[indexAgesStep].querySelector('.step-warning');
      const msgRequired = stepWarningWrapperElement.getAttribute('data-msg-required');
      if (validStep1) {
        if (!window.InvalidAgesQuantity && !window.LimitPaxReached) {
          stepWarningWrapperElement.textContent = '';
        }
      } else {
        if (!window.InvalidAgesQuantity && !window.LimitPaxReached) {
          stepWarningWrapperElement.textContent = msgRequired;
        }
      }
    }
    // Validate Step 2
    if (currentStep === indexDateStep) {
      const d = window.calendar.getDate();
      const monthN = (d.getMonth() + 1).toString().padStart(2, '0');
      const dayN = d.getDate().toString().padStart(2, '0');
      TicketData.Date = dayN + '-' + monthN  + '-' +  d.getFullYear();
      validStep2 = true;
    }
    // Validate Step 3
    if (currentStep === indexHourStep) {
      const stepWarningWrapperElement = steps[indexHourStep].querySelector('.step-warning');
      const msgRequired = stepWarningWrapperElement.getAttribute('data-msg-required');
      validStep3 = validateStep3(); // In this case is returning the radio input
      if (validStep3) {
        refreshStepDetails(indexHourStep, validStep3.value + 'h');
        refreshSummaryTime(validStep3.value + 'h');
        TicketData.Time = document.querySelector('.radio-hour:checked').value + 'h';
        refreshWarning(indexHourStep, '');
        setAvailableServices(ajaxRequest);
        TicketData.Departure = parseInt(validStep3.getAttribute('data-departure-id'));
      } else {
        refreshStepDetails(indexHourStep, '');
        refreshSummaryTime('---');
        if (ExcededPlaces) {
          const msgExcededPlaces = stepWarningWrapperElement.getAttribute('data-msg-limit-exceded');
          refreshWarning(indexHourStep, msgExcededPlaces);
        } else {
          refreshWarning(indexHourStep, msgRequired);
        }
        TicketData.Departure = false;
      }
    }
    // Validate Step 4
    if (currentStep === indexServiceStep) {
      const stepWarningWrapperElement = steps[indexServiceStep].querySelector('.step-warning');
      const sameServicesRadio = document.querySelector('.same-service-radio');
      const multipleServicesRadio = document.querySelector('.multiple-services-radio');
      if (sameServicesRadio && sameServicesRadio.checked) {
        const serviceSelect = document.querySelector('.services-info__same-services .service-select');
        if (serviceSelect.value.length <= 0) {
          validStep4 = false;
        }
      }
      if (multipleServicesRadio && multipleServicesRadio.checked) {
        const serviceSelects = document.querySelectorAll('.services-info__multiple-services .service-select');
        serviceSelects.forEach((sel) => {
          if (sel.value.length <= 0) {
            validStep4 = false;
          }
        });
      }
      refreshWarning(indexServiceStep, '');
      if (!validStep4 && stepWarningWrapperElement) {
        const msgRequired = stepWarningWrapperElement.getAttribute('data-msg-required');
        refreshWarning(indexServiceStep, msgRequired);
      }
    }
    let valid = false;
    if (currentStep >= indexAgesStep) {
      valid = validStep1;
      steps[indexAgesStep].setAttribute('data-valid', valid ? true : false);
    }
    if (currentStep >= indexDateStep) {
      valid = validStep1 && validStep2;
      steps[indexDateStep].setAttribute('data-valid', valid ? true : false);
    }
    if (currentStep >= indexHourStep) {
      valid = validStep1 && validStep2 && validStep3;
      steps[indexHourStep].setAttribute('data-valid', valid ? true : false);
    }
    if (currentStep >= indexServiceStep) {
      valid = validStep1 && validStep2 && validStep3 && validStep4;
      steps[indexServiceStep].setAttribute('data-valid', valid ? true : false);
    }
    RecheckStep(StepType.SELECT_AGE, prevInputs, nextInputs);
    return valid;
  };
  const refreshSubmitBtn = (enable = true) => {
    const step5Wrapper = document.querySelector('.step-wrapper[data-step-index="4"]');
    const nextStepButton = document.querySelector('.next-step-button-finish');
    if (step5Wrapper && nextStepButton) {
      if (enable) {
        const validStr = steps[StepType.SELECT_SERVICE].getAttribute('data-valid');
        if (validStr && validStr === 'true' && currentStep >= 4) {
          nextStepButton.classList.remove('button--disabled');
          nextStepButton.classList.add('button--yellow');
          nextStepButton.classList.add('button--stretch');
          nextStepButton.removeAttribute('disabled');
        } else {
          nextStepButton.setAttribute('disabled', 'true');
          nextStepButton.classList.add('button--disabled');
          nextStepButton.classList.remove('button--yellow');
          nextStepButton.classList.remove('button--stretch');
        }
      } else {
        nextStepButton.setAttribute('disabled', 'true');
        nextStepButton.classList.add('button--disabled');
        nextStepButton.classList.remove('button--yellow');
        nextStepButton.classList.remove('button--stretch');
      }
      if (window.screen.availWidth < 992) {
        nextStepButton.classList.remove('button--disabled');
        nextStepButton.classList.add('button--yellow');
        nextStepButton.classList.add('button--stretch');
        nextStepButton.removeAttribute('disabled');
      }
    }
  };
  refreshSubmitBtn(false); // init refresh for enable in mobile or disable in desktop for default
  if (document.querySelector('.next-step-button-finish')) {
    document.querySelector('#pill-tickets-tab').setAttribute('data-status', StepStatus.PENDING);
    document.querySelector('#pill-data-tab').setAttribute('data-status', StepStatus.PENDING);
    document.querySelector('.next-step-button-finish').addEventListener('click', (e) => {
      if (currentStep <= StepType.SELECT_SERVICE) {
        e.preventDefault();
        e.stopPropagation();
        const stepEl = getStepElement(currentStep);
        stepEl.querySelector('.step-continue').click();
        fillTicketDetails();
        refreshAllSummary();
        return false;
      } else {
        const stepValid = validateStep4();
        if (stepValid) {
          document.querySelector('#pill-tickets-tab').setAttribute('data-status', StepStatus.VALIDATED);
        } else {
          document.querySelector('#pill-tickets-tab').setAttribute('data-status', StepStatus.PENDING);
        }
        refreshAllSummary();
      }
    });
  }
  const currentActivity = () => {
    return parseInt(getSummaryWrapperElement().getAttribute('data-activity-id'));
  };
  const setAvailableServices = (ajaxRequest = true) => {
    document.querySelector('.services-info.info-wrapper').innerHTML = '<span>'+loadingIcon()+'</span>';
    document.querySelector('.next-step-button-finish').classList.add('button--disabled');
    document.querySelector('.next-step-button-finish').classList.remove('button--yellow');
    document.querySelector('.next-step-button-finish').classList.remove('button--stretch');
    if (ajaxRequest) {
      $.ajax({
        type: 'post',
        url: '/wp/wp-admin/admin-ajax.php',
        data: {
          action: 'services-availables',
          activity: currentActivity(),
          ageQuantities: agesQuantities,
        },
        dataType: 'json',
        success: function (response) {
          document.querySelector('#services-wrapper').innerHTML = response.partialHtml;
          const sameServicesRadio = document.querySelector('.same-service-radio');
          const multipleServicesRadio = document.querySelector('.multiple-services-radio');
          const sameServiceSection = document.querySelector('.services-info__same-services');
          const multipleServiceSection = document.querySelector('.services-info__multiple-services');
          sameServicesRadio.addEventListener('click', () => {
            sameServiceSection.style.display = 'block';
            multipleServiceSection.style.display = 'none';
          });
          multipleServicesRadio.addEventListener('click', () => {
            multipleServiceSection.style.display = 'block';
            sameServiceSection.style.display = 'none';
            //document.querySelector('.next-step-button').classList.remove('button--disabled');
            //document.querySelector('.next-step-button').classList.add('button--yellow');
            //document.querySelector('.next-step-button').classList.add('button--stretch');
          });
          setStepHeight(currentStep, true);
          document.querySelector('.service-select').addEventListener('change', (e) => {
            //document.querySelector('.next-step-button').classList.remove('button--disabled');
            //document.querySelector('.next-step-button').classList.add('button--yellow');
            //document.querySelector('.next-step-button').classList.add('button--stretch');
            const valueToSetOnSelects = e.target.value;
            const selects = document.querySelectorAll('.services-info__multiple-services .service-select');
            selects.forEach((el) => {
              const ageId = parseInt(el.getAttribute('data-age-id'));
              if (ageId >= 1 && ageId <= 2) {
                el.value = valueToSetOnSelects;
              }
            });
          });
          LoadServicesEvents();
        },
      });
    }
  };
  const LoadServicesEvents = () => {
    const DifferentService = document.querySelector('#different-service');
    document.querySelector('.services-info__same-services .service-select').addEventListener('change', () => {
      document.querySelector('.services-info__same-services .text-check-icon').classList.add('visible');
      feather.replace();
    });
    DifferentService.addEventListener('change', () => {
      const serviceSelects = document.querySelectorAll('.services-info__multiple-services .service-select');
      serviceSelects.forEach((s) => {
        s.addEventListener('change', () => {
          let anyEmpty = false;
          const serviceSelects = document.querySelectorAll('.services-info__multiple-services .service-select');
          serviceSelects.forEach((s) => {
            if (!anyEmpty) {
              anyEmpty = s.value.length <= 0;
            }
          });
          if (!anyEmpty) {
            document.querySelectorAll('.services-info__multiple-services .text-check-icon').forEach((x) => {
              x.classList.add('visible');
            });
            feather.replace();
          } else {
            document.querySelectorAll('.services-info__multiple-services .text-check-icon').forEach((x) => {
              x.classList.remove('visible');
            });
          }
        });
      });
    });
  };
  let ExcededPlaces = false;
  const validateStep3 = () => {
    let selectedHourElement = document.querySelector('.radio-hour:checked');
    if (selectedHourElement) {
      ExcededPlaces = false;
      if (selectedHourElement.nextElementSibling.classList.contains('limit-exceded')) {
        selectedHourElement = false; // Invalid hour
        ExcededPlaces = true;
      }
    }
    return selectedHourElement;
  };
  const fillTicketDetails = () => {
    TicketData.Details = [];
    const selects = document.querySelectorAll('.services-info__multiple-services select');
    selects.forEach((el) => {
      const ageId = parseInt(el.getAttribute('data-age-id'));
      const ageName = el.getAttribute('data-age-name');
      const selectedOpt = el.options[el.selectedIndex];
      if (selectedOpt) {
        const serviceId = selectedOpt.value;
        const serviceName = selectedOpt.textContent;
        const serviceDescription = selectedOpt.getAttribute('data-service-description');
        const serviceDescriptionB64 = decodeURIComponent(escape(atob(serviceDescription)));
        let existAgeInArr = false;
        TicketData.Details.forEach((el, i) => {
          if (el.AgeId === ageId) {
            existAgeInArr = i;
          }
        });
        const ServiceObj = { ServiceName: serviceName, ServiceDescription: serviceDescriptionB64, ServiceId: parseInt(el.value), LinePrice:  getActivityPrice(ageId, serviceId)};
        if (existAgeInArr !== false) {
          if (!TicketData.Details[existAgeInArr].Lines) {
            TicketData.Details[existAgeInArr].Lines = [];
          }
          TicketData.Details[existAgeInArr].Lines.push(ServiceObj);
          const lines = TicketData.Details[existAgeInArr].Lines;
          const value = TicketData.Details[existAgeInArr].Quantity+1;
          TicketData.Details[existAgeInArr] = {AgeId: ageId, AgeName: ageName, Quantity: parseInt(value), Lines: lines };
        } else {
          TicketData.Details[ageId] = {AgeId: ageId, AgeName: ageName, Quantity: 1 };
          TicketData.Details[ageId].Lines = [];
          TicketData.Details[ageId].Lines.push(ServiceObj);
        }
      }
    });
  }
  const validateStep4 = () => {
    const d = window.calendar.getDate();
    const monthN = (d.getMonth() + 1).toString().padStart(2, '0');
    const dayN = d.getDate().toString().padStart(2, '0');
    TicketData.Quantities = agesQuantities;
    TicketData.Date = dayN + '-' + monthN  + '-' +  d.getFullYear();
    TicketData.Time = document.querySelector('.radio-hour:checked').value + 'h';
    fillTicketDetails();
    const checkedYes = document.querySelector('#same-service').checked;
    const checkedNo = document.querySelector('#different-service').checked;
    const valid = (checkedYes && getStepElement(StepType.SELECT_SERVICE).querySelector('.service-select') && getStepElement(StepType.SELECT_SERVICE).querySelector('.service-select').value.length > 0) || checkedNo;
    steps[StepType.SELECT_SERVICE].setAttribute('data-valid', valid);
    const navLinkTicket = document.querySelector('.header-tickets-tab');
    const navLinkData = document.querySelector('.header-data-tab');
    const navLinkConfirmation = document.querySelector('.header-confirmation-tab');
    const contentTickets = document.querySelector('.content-tickets-tab');
    const contentData = document.querySelector('.content-data-tab');
    const contentConfirmation = document.querySelector('.content-confirmation-tab');
    const contentAll = [contentTickets, contentData, contentConfirmation];
    const navLinksAll = [navLinkTicket, navLinkData, navLinkConfirmation];
    navLinksAll.forEach((tab) => {
      tab.classList.remove('active');
    });
    contentAll.forEach((content) => {
      content.classList.remove('active', 'show');
    });
    navLinkData.classList.add('active');
    contentData.classList.add('active', 'show');
    return valid;
  };
  const refreshAllSummary = () => {
      refreshSummaryProducts();
      const ticketWrapperEl = document.querySelector('#summary-aside-wrapper');
      let html = '';
      let totalPriceTicket = 0;
      let ServicesShowed = [];
      TicketData.Details.forEach((serviceObj) => {
        if (serviceObj) {
          serviceObj.Lines.forEach((line) => {
            const key = line.ServiceName + '-' + serviceObj.AgeId;
            if (!ServicesShowed.includes(key)) {
              ServicesShowed.push(key);
              const price = getActivityPrice(serviceObj.AgeId, line.ServiceId);
              let quantityByService = 0;
              serviceObj.Lines.forEach((l) => {
                if (l.ServiceName === line.ServiceName) {
                  quantityByService ++;
                }
              });
              const totalPrice = price*quantityByService;
              totalPriceTicket+= totalPrice;
              html += `
            <div class="d-flex justify-content-between ticket-line">
                <p>
                  <span class="number-people">${quantityByService}</span> <span class="type-people">${serviceObj.AgeName}</span> x <span class="single-price-ticket">${price.toLocaleString('es')}€</span> - <span class="service">${line.ServiceName}</span>
                </p>
                <p>
                  <span class="total-price-ticket">${totalPrice.toLocaleString('es')}€</span>
                </p>
            </div>
            `;
            }
          });
        }
      });
      totalPriceTicket = (Math.round(totalPriceTicket * 100) / 100).toFixed(2).replace('.', ',');
      if (TicketData.Details.length > 0) {
        GetCurrentOrderPrice((res) => {
          ticketWrapperEl.querySelectorAll('.ticket-line').forEach((el) => {el.remove(); }); // Remove previous content
          if (res && res.price) {
            totalPriceTicket = res.price.toFixed(2).replace('.', ',');
            ticketWrapperEl.querySelector('.tickets-data').innerHTML = html;
            ticketWrapperEl.querySelector('.total-price-wrapper .total-price').textContent = totalPriceTicket + '€';
            const totalPricesTicket = document.querySelectorAll('.total-wrapper .price .value');
            totalPricesTicket.forEach((el) => {
              el.textContent = totalPriceTicket + '€';
            });
            const discountsWrapper = document.querySelector('#summary-aside-wrapper .discounts-wrapper');
            if (res.discount > 0) { // Have discount, checking from the API
              discountsWrapper.classList.remove('d-none');
              discountsWrapper.querySelector('.item-name').textContent = res.discountDescription;
              discountsWrapper.querySelector('.total-price-item').textContent = '-' + res.discount + '%';
            } else {
              discountsWrapper.classList.add('d-none');
            }
            const showProductDiscounts = false;
            if (showProductDiscounts) {
              const productDiscountsWrapper = document.querySelector('#summary-aside-wrapper .products-discounts-wrapper');
              if (productDiscountsWrapper && res.productDiscounts && res.productDiscounts.length > 0) {
                productDiscountsWrapper.classList.remove('d-none');
                let htmlProductDiscounts = '';
                const productDiscountsLbl = productDiscountsWrapper.getAttribute('data-discount-label');
                res.productDiscounts.forEach((prodDiscount) => {
                  if (prodDiscount.discountType === 0) {
                    prodDiscount.discountPerUnitValue *= prodDiscount.quantity;
                  }
                  htmlProductDiscounts += `
            <div class="d-flex justify-content-between align-items-center">
              <p class="m-0">
                <span class="item-name">${prodDiscount.productName} - ${productDiscountsLbl}</span>
              </p>
              <p class="m-0">
                <span class="total-price-item">-${prodDiscount.discountPerUnitValue + (prodDiscount.discountType === 0 ? '€': '%')}</span>
              </p>
            </div>
            `;
                });
                productDiscountsWrapper.innerHTML = htmlProductDiscounts;
                //productDiscountsWrapper.querySelector('.total-price-item').textContent += '-' + prodDiscount.discountValue + (prodDiscount.discountType === 0 ? '€': '%');
              } else {
                productDiscountsWrapper.classList.add('d-none');
              }
            }
          }
        });
      }
  };
  const GetCurrentOrderPrice = (callbackOnSuccess) => {
    if (!TicketData.UseCoupon) {
      delete TicketData.UseCoupon;
    }
    TicketData.Products = [];
    refreshSummaryProducts(true);
    $.ajax({
      type: 'post',
      url: '/wp/wp-admin/admin-ajax.php',
      data: {
        action: 'order-price',
        ticket: TicketData,
      },
      dataType: 'json',
      success: function (response) {
        callbackOnSuccess(response);
      },
    });
  };
  setInterval(refreshAllSummary, 5000);
};

export { StepsSwitcher, TicketData, StepType, StepStatus, loadingIcon };

import feather from 'feather-icons';
import Swiper, { Navigation, Pagination} from 'swiper';
import ReadMoreButton from '../modules/readMoreButton.js';
import InitCalendar from '../modules/calendar';
import ModalOffers from '../modules/modalOffers';
import FixAnchorScrolling from '../modules/fixAnchorScrolling';
import {StepsSwitcher} from '../modules/steps';
import Quantity from '../modules/quantity-info';
import HeaderTabSystem from '../modules/headerTabSystem';
import FunnelData from '../modules/funnelData';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    ReadMoreButton();
    StepsSwitcher();
    Quantity();
    InitCalendar();
    ModalOffers();
    FixAnchorScrolling();
    HeaderTabSystem();
    FunnelData();
    try {
      feather.replace(); // Load the icons from feather library
    } catch (error) {
      console.error(error);
    }
    // Global Swiper - Load all swiper and the configurations
    const swiperComponentsSelector = '.global-swiper';
    const swiperComponents = [];
    if ($(swiperComponentsSelector).length > 0) {
      $(swiperComponentsSelector).each(function (i, el) {
        let args = $(el).data('swiper-args');
        if (typeof (args) === 'undefined') {
          args = false;
        }
        else {
          args.modules = [Navigation, Pagination];
        }
        const swiperObj = new Swiper(el, args);
        swiperObj.uid = $(this).data('swiper-uid');
        if (swiperObj.params.centeredSlides) {
          swiperObj.slideTo(1);
        }
        swiperComponents.push(swiperObj);
      });
      console.log('Global Swiper Loaded [' + swiperComponents.length + ']');
      window.swiperComponents = swiperComponents;
      $(document).on('click', 'a[data-swiper-prev]', function (e) {
        e.preventDefault();
        const swiperUID = $(this).data('swiper-prev');
        swiperComponents.forEach(function (el) {
          if (el.uid === swiperUID) {
            el.slidePrev();
          }
        });
      });
      $(document).on('click', 'a[data-swiper-next]', function (e) {
        e.preventDefault();
        const swiperUID = $(this).data('swiper-next');
        swiperComponents.forEach(function (el) {
          if (el.uid === swiperUID) {
            el.slideNext();
          }
        });
      });
    }
    // Menu Launcher (Mobile/Tablet)
    $('.header-wrapper__logo-burger .logo-burger__burger-menu').on('click', function (e) {
      e.preventDefault();
      $('.burger-main-menu').addClass('active');
    });
    // Menu Close (Mobile/Tablet)
    $('.close-mobile-menu').on('click', function (e) {
      e.preventDefault();
      $('.burger-main-menu').removeClass('active');
    });
    // Menu Scroll handler
    $(window).on('scroll', function () {
      if (document.querySelector('body') && document.querySelector('body').classList.contains('page-template-template-funnel')) {
        return;
      }
      if (document.documentElement.scrollTop > 100) {
        $('.main-header').addClass('white-mode');
        $('.button--question').addClass('button--question-modify');
        $('.button--blue').addClass('button--blue-modify');
        $('body').addClass('on-scroll');
      } else {
        $('.main-header').removeClass('white-mode');
        $('.button--question').removeClass('button--question-modify');
        $('.button--blue').removeClass('button--blue-modify');
        $('body').removeClass('on-scroll');
      }
    });
    // Global Launcher Modal
    $('*[data-launch-modal]').on('click', function (e) {
      e.preventDefault();
      const modalSelector = $(this).data('launch-modal');
      $(modalSelector).modal('show');
    });
    //Open modal when clicking the "+" icon in activities home
    let idPost = $('.activity-card').attr('data-open-activity-modal');
    setTimeout(() => {
      $('#more-info-modal-' + idPost).modal('show');
    }, 50);

    if ($('.booking-form').data('sended')) {
      const errors = $('.booking-form .gform_validation_errors').length > 0;
      if (!errors) {
        $('#modalBooking').modal('show');
      }
    }

    if ($('.contact').data('sended')) {
      const errors = $('.contact .gform_validation_errors').length > 0;
      if (!errors) {
        $('#modalBooking').modal('show');
      }
    }

    if ($('.newsletter').data('sended')) {
      const errors = $('.newsletter .gform_validation_errors').length > 0;
      if (!errors) {
        $('#modalNewsletter').modal('show');
      }
    }

    //Add src iframe when open video-modal and remove when close
    const videoSrc = $('.video-modal').attr('data-link-video' + '');

    $('#videoModal').on('shown.bs.modal', function () {
      $('.video-modal .modal-body iframe').attr('src', videoSrc + '?autoplay=1&mute=1');
    });

    $('#videoModal').on('hide.bs.modal', function () {
      $('.video-modal .modal-body iframe').attr('src', ''); // Remove the video source.
    });
  },
};

// NAVIGATION BETWEEN TABS
import {loadingIcon, StepStatus, TicketData} from './steps';

// eslint-disable-next-line no-unused-vars
function WPPostJSONRequest(Endpoint, Data, Callback){
  const headers = new Headers({
    'Content-Type': 'application/json; charset=utf-8',
  });
  fetch(Endpoint, {
    method: 'POST',
    body: JSON.stringify(Data),
    headers: headers,
    credentials: 'same-origin',
  }).then(response => response.json()).then(json => {
    if (Callback) {
      Callback(json);
    }
  });
}

function headerTabSystem() {
  // header tabs
  const navLinkTicket = document.querySelector('.header-tickets-tab');
  const navLinkData = document.querySelector('.header-data-tab');
  // const navLinkPayment = document.querySelector('.header-payment-tab');
  //const navLinkConfirmation = document.querySelector('.header-confirmation-tab');
  const navLinksAll = [navLinkTicket, navLinkData];//, navLinkConfirmation];
  // content tabs
  const contentTickets = document.querySelector('.content-tickets-tab');
  const contentData = document.querySelector('.content-data-tab');
  // const contentPayment = document.querySelector('.content-payment-tab');
  //const contentConfirmation = document.querySelector('.content-confirmation-tab');
  const contentAll = [contentTickets, contentData];//, contentConfirmation];
  function nextTab (headerTab, contentTab) {
    // removing classes
    navLinksAll.forEach((tab) => {
      tab.classList.remove('active');
    });
    contentAll.forEach((content) => {
      content.classList.remove('active', 'show');
    });
    // add class to specific tab & content
    headerTab.classList.add('active');
    contentTab.classList.add('active', 'show');
  }
  const pillDataTab = document.querySelector('#pill-data-tab');
  if (pillDataTab != null) {
    document.querySelector('#pill-data-tab').addEventListener('show.bs.tab', (e) => {
      const currentActiveTab = document.querySelector('#pill-tickets-tab');
      const validatedActive = parseInt(currentActiveTab.getAttribute('data-status')) === StepStatus.VALIDATED;
      if (!validatedActive || TicketData.Completed) {
        e.stopImmediatePropagation();
        e.preventDefault();
      }
    });
  }
  const pillTicketTab = document.querySelector('#pill-tickets-tab');
  if (pillTicketTab) {
    pillTicketTab.addEventListener('show.bs.tab', (e) => {
      if (TicketData.Completed) {
        e.stopImmediatePropagation();
        e.preventDefault();
      }
    });
  }

  // tickets tab next button
  const next = document.querySelector('.next-step-button');
  if (next) {
    next.addEventListener('click', () => {
      const readyTicketsTab = document.querySelector('#pill-tickets-tab').getAttribute('data-status');
      if (readyTicketsTab && readyTicketsTab === 'validated' && !TicketData.Completed) {
        if (!next.classList.contains('button--disabled')) {
          window.scrollTo(0, 0);
          return nextTab(navLinkData, contentData);
        }
      }
    });
  }

  let createdOrder = false;
  const submitDataForm = () => {
    const form = document.querySelector('#content-data form');
    const formIdData = parseInt(form.getAttribute('id').replace('gform_', ''));

    const lang = form.closest('.content-dataform').getAttribute('data-order-lang');

    // Manual validation if need invoice
    const inputInvoiceSelector = '.booking-invoice input';
    const inputBusinessSelector = '.booking-business input';
    const inputCifSelector = '.booking-cif input';
    const inputNationalitySelector = '.booking-nationality select';
    const inputPostalCodeSelector = '.booking-postal-code input';
    const inputMobileSelector = '.booking-phone input';
    const inputInvoiceChecked = form.querySelector(inputInvoiceSelector).checked;
    const inputBusinessValue = form.querySelector(inputBusinessSelector).value;
    const inputCifValue = form.querySelector(inputCifSelector).value;
    const inputNationalityValue = form.querySelector(inputNationalitySelector).value;
    const inputMobileValue = form.querySelector(inputMobileSelector).value;
    if (inputNationalityValue.toUpperCase() === 'ES') {
      const validPC = form.querySelector(inputPostalCodeSelector).value && form.querySelector(inputPostalCodeSelector).value.length > 0;
      if (validPC) {
        form.querySelector(inputPostalCodeSelector).closest('.gfield').classList.remove('gfield_error');
      } else {
        form.querySelector(inputPostalCodeSelector).closest('.gfield').classList.add('gfield_error');
        console.log('Invalid data detected: no valid postal code');
        return;
      }
    }
    if (inputInvoiceChecked) {
      const valid = inputBusinessValue.length > 0 && inputCifValue.length > 0;
      if (!valid) {
        if (inputBusinessValue.length <= 0) {
          form.querySelector(inputBusinessSelector).closest('.gfield').classList.add('gfield_error');
        } else {
          form.querySelector(inputBusinessSelector).closest('.gfield').classList.remove('gfield_error');
        }
        if (inputCifValue.length <= 0) {
          form.querySelector(inputCifSelector).closest('.gfield').classList.add('gfield_error');
        } else {
          form.querySelector(inputCifSelector).closest('.gfield').classList.remove('gfield_error');
        }
        console.log('Invalid data detected: no valid business or cif');
        return;
      }
    }

    const orderObj = {
      'Name': form.querySelector('.booking-name input').value,
      'Lastname': form.querySelector('.booking-surname input').value,
      'Mail': form.querySelector('.booking-mail input').value,
      'DNI': form.querySelector('.booking-dni input').value,
      'Nationality': inputNationalityValue,
      'ReferalSource': form.querySelector('.booking-source select').value,
      'Comments': form.querySelector('.booking-comments textarea').value,
      'Language': lang,
      'Invoice': inputInvoiceChecked,
      'PaymentMethod': 1, // Tarjeta mediante Redsys
      'Company': inputBusinessValue,
      'CIF': inputCifValue,
      'DepartureId': TicketData.Departure,
      'PostalCode':  form.querySelector(inputPostalCodeSelector).value,
      'Mobile': inputMobileValue,
    };
    orderObj.OrderLines = [];
    TicketData.Details.forEach((detail) => {
      const ageId = detail.AgeId;
      detail.Lines.forEach((line) => {
        const serviceId = line.ServiceId;
        orderObj.OrderLines.push({AgeId: ageId, ServiceId: serviceId});
      });
    });
    orderObj.ProductLines = TicketData.Products;
    if (TicketData.UseCoupon) {
      if (TicketData.UseCoupon.CouponFrom === 'gift') {
        orderObj.RegalaCouponUsed = TicketData.UseCoupon.CouponId;
      }
      if (TicketData.UseCoupon.CouponFrom === 'booking') {
        orderObj.CouponId = TicketData.UseCoupon.CouponId;
      }
    }
    const CreatePaymentForm = (wrapperElement, formAction, formData) => {
      const signatureVersion = formData.ds_SignatureVersion;
      const merchantParameters = formData.ds_MerchantParameters;
      const signature = formData.ds_Signature;
      // Creating the form element
      let form = document.createElement('form');
      form.action = formAction;
      form.method = 'POST';
      // Create the hidden input "Ds_SignatureVersion"
      let inputSV = document.createElement('input');
      inputSV.type = 'hidden';
      inputSV.name = 'Ds_SignatureVersion';
      inputSV.value = signatureVersion;
      form.appendChild(inputSV);
      // Create the hidden input "Ds_MerchantParameters"
      let inputMP = document.createElement('input');
      inputMP.type = 'hidden';
      inputMP.name = 'Ds_MerchantParameters';
      inputMP.value = merchantParameters;
      form.appendChild(inputMP);
      // Create the hidden input "Ds_Signature"
      let inputSignature = document.createElement('input');
      inputSignature.type = 'hidden';
      inputSignature.name = 'Ds_Signature';
      inputSignature.value = signature;
      form.appendChild(inputSignature);
      // Add to the wrapper
      wrapperElement.appendChild(form);
      return form;
    };
    // Guardaremos datos a modo de log en gravity forms
    $(document).off('gform_confirmation_loaded');
    $(document).on('gform_confirmation_loaded', function(event, formId){
      if (createdOrder) {
        console.log('Reserva ya creada.');
        return;
      }
      createdOrder = true;
      if (formId === formIdData) {
        changeTotalWrapper();
        WPPostJSONRequest('/wp-json/order/create', orderObj, (orderCreatedResponse) => {
          if (!orderCreatedResponse || (orderCreatedResponse != null && orderCreatedResponse.status === 400)) {
            changeTotalWrapper('Error al crear la reserva, No hay suficientes plazas');
            console.log('Error al crear la reserva. Faltan plazas para asignar');
            return;
          }
          document.querySelector('#pill-data-tab').setAttribute('data-status', StepStatus.VALIDATED);
          window.scrollTo(0, 0);
          TicketData.Completed = true;
          const paymentUrlOK = document.querySelector('#summary-aside-wrapper').getAttribute('data-payment-url-ok');
          const paymentPayload = {'OrderId': orderCreatedResponse.orderId, 'ConfirmationURLOK': paymentUrlOK + '?key='+orderCreatedResponse.orderKey, 'ConfirmationURLKO': paymentUrlOK};
          WPPostJSONRequest('/wp-json/order/payment', paymentPayload, (orderPaymentResponse) => {
            const paymentLinkElement = document.getElementById('funnelPaymentUrl');
            document.getElementById('funnelConfirmationModal').addEventListener('shown.bs.modal', function () {
              changeTotalWrapper('');
              document.querySelector('#pill-data-tab').classList.remove('active');
              document.querySelector('.header-payment-tab').classList.remove('disabled');
              document.querySelector('.header-payment-tab').classList.add('active');
            });
            $(document.getElementById('funnelConfirmationModal')).modal('show');
            paymentLinkElement.addEventListener('click', (e) => {
              e.preventDefault();
              const wrapperPaymentForm = paymentLinkElement.parentElement;
              const formPayment = CreatePaymentForm(wrapperPaymentForm, orderPaymentResponse.paymentActionURL, orderPaymentResponse.formData);
              formPayment.submit();
            });
          });
          // return nextTab(navLinkConfirmation, contentConfirmation);
        });
      }
    });
    form.submit();
  };
  const changeTotalWrapper = (showContent = false) => {
    const totalWrappers = document.querySelectorAll('.total-wrapper');
    totalWrappers.forEach((el) => {
      if (!showContent) {
        el.innerHTML = loadingIcon();
      } else {
        el.innerHTML = showContent;
      }
    });
  };
  // data form tab next button
  const nextForm = document.querySelector('.next-button-data-form');
  if (nextForm) {
    nextForm.addEventListener('click', submitDataForm);
  }
}

export default headerTabSystem;

import {loadingIcon, TicketData} from './steps';
const feather = require('feather-icons');

function WPPostRequest(Endpoint, ActionName, Args, Callback){
  const headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
  });
  let body = 'action='+ActionName;
  if (Args) {
    body += '&'+Args;
  }
  fetch(Endpoint, {
    method: 'POST',
    body: body,
    headers: headers,
    credentials: 'same-origin',
  }).then(response => response.json()).then(json => {
    if (Callback) {
      Callback(json);
    }
  });
}

const FunnelData = () => {
  const parentWrapperSelector = '#content-data';
  const GetCurrentOrderPrice = (callbackOnSuccess) => {
    const ticket = TicketData;
    if (!ticket.UseCoupon) {
      delete ticket.UseCoupon;
    }
    $.ajax({
      type: 'post',
      url: '/wp/wp-admin/admin-ajax.php',
      data: {
        action: 'order-price',
        ticket: ticket,
      },
      dataType: 'json',
      success: function (response) {
        callbackOnSuccess(response);
      },
    });
  };
  let BtnUseCouponOriginalLabelText = '';
  const UseCoupon = (e) => {
    e.preventDefault();
    if (TicketData.UseCoupon) {
      ChangeUseCouponButton(false);
    }
    const inputCoupon = e.target.closest('.gfield').previousSibling.querySelector('input');
    const couponCodeInput = inputCoupon.value;
    const iconStyle = {
      height: 16,
      width: 16,
      objectFit: 'contain',
      display: 'block',
      position: 'absolute',
      right: 18,
      top: 'calc(50% - 16px/2)',
      color: '#3ccd4a',
    };
    inputCoupon.parentElement.classList.add('position-relative');
    let loadingIconEl = $(loadingIcon());
    loadingIconEl.css(iconStyle);
    loadingIconEl.css('right', 60);
    loadingIconEl.attr('id', 'loadingIcon');
    $(inputCoupon.parentElement).append(loadingIconEl);
    WPPostRequest('/wp/wp-admin/admin-ajax.php', 'order-coupon', 'code=' + couponCodeInput, (response) => {
      const previousCheckIcon = inputCoupon.parentElement.querySelector('.text-check-icon');
      if (previousCheckIcon) {
        previousCheckIcon.remove();
      }
      if (response && (response.code || response.coupon_code)) {
        ChangeUseCouponButton();
        const circleIcon = $('<i data-feather="check-circle" class="text-check-icon"></i>');
        $(inputCoupon.parentElement).append(circleIcon);
        $(circleIcon).css(iconStyle);
        if (response.coupon_regala) {
          TicketData.UseCoupon = { CouponFrom: 'gift', CouponCode: response.coupon_code, CouponId: response.id };
        } else {
          TicketData.UseCoupon = { CouponFrom: 'booking', CouponCode: response.code, CouponId: response.id };
        }
      } else {
        TicketData.UseCoupon = false;
        if (inputCoupon.value.length > 0) {
          const circleIcon = $('<i data-feather="x-circle" class="text-check-icon"></i>');
          $(inputCoupon.parentElement).append(circleIcon);
          $(circleIcon).css(iconStyle);
          $(circleIcon).css('color', 'red');
        }
      }
      feather.replace();
      const loadingIcon = document.getElementById('loadingIcon');
      if (loadingIcon) {
        loadingIcon.remove();
      }
      GetCurrentOrderPrice(RefreshPricesData);
    });
  };
  let nTriesPricesData = 0;
  const RefreshPricesData = (res) => { // Do request passing the TicketData
    const ticketWrapperEl = document.querySelector('#summary-aside-wrapper');
    const discountsWrapper = document.querySelector('#summary-aside-wrapper .discounts-wrapper');
    if (res) {
      if (res.discount > 0) { // Have discount, checking from the API
        discountsWrapper.classList.remove('d-none');
        discountsWrapper.querySelector('.item-name').textContent = res.discountDescription;
        discountsWrapper.querySelector('.total-price-item').textContent = '-' + res.discount + '%';
      } else {
        if (res.bookingCoupon || res.giftCoupon) {
          if (res.bookingCoupon) {
            discountsWrapper.classList.remove('d-none');
            const labelDiscount = discountsWrapper.getAttribute('data-label-discount');
            discountsWrapper.querySelector('.item-name').textContent = labelDiscount + ' (' + res.bookingCoupon.description + ')';
            const typeStr = res.bookingCoupon.type === 1 ? '%' : '€';
            discountsWrapper.querySelector('.total-price-item').textContent = '-' + res.bookingCoupon.discount + '' + typeStr ;
          }
          if (res.giftCoupon) {
            discountsWrapper.classList.remove('d-none');
            const labelDiscount = discountsWrapper.getAttribute('data-label-discount');
            discountsWrapper.querySelector('.item-name').textContent = labelDiscount + ' (' + res.giftCoupon.coupon_code + ')';
            discountsWrapper.querySelector('.total-price-item').textContent = '-' + res.giftCoupon.real_price + '€';
          }
        } else {
          discountsWrapper.classList.add('d-none');
        }
      }
      const totalPriceTicket = res.price.toFixed(2).replace('.', ',');
      ticketWrapperEl.querySelector('.total-price-wrapper .total-price').textContent = totalPriceTicket + '€';
      const totalPricesTicket = document.querySelectorAll('.total-wrapper .price .value');
      totalPricesTicket.forEach((el) => {
        el.textContent = totalPriceTicket + '€';
      });
    }
    else {
      nTriesPricesData++;
      if (nTriesPricesData <= 2) {
        GetCurrentOrderPrice(RefreshPricesData);
      }
    }
  };
  const ChangeUseCouponButton = (cancelText = true) => {
    const btn = document.querySelector('.cuppon-link-submit');
    let lblCancel = btn.getAttribute('data-label-cancel');
    if (cancelText) {
      BtnUseCouponOriginalLabelText = btn.textContent;
      if (!lblCancel) {
        btn.textContent = 'Anular cupón'; // default text
      } else {
        btn.textContent = lblCancel;
      }
    }
    else {
      btn.textContent = BtnUseCouponOriginalLabelText; // original text
      const inputCoupon = btn.closest('.gfield').previousSibling.querySelector('input');
      inputCoupon.value = '';
    }
  };
  const couponLink = document.querySelector(parentWrapperSelector + ' .cuppon-link-submit');
  if (couponLink) {
    $(document).on('click', '.cuppon-link-submit', (e) => {
      UseCoupon(e);
    });
  }
};
export default FunnelData;

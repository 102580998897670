export default function() {
  (function($, window) {
    const adjustAnchor = function() {
      const $anchor = $(':target'),
        fixedElementHeight = $('.main-header').height()+30;
      if ($anchor.length > 0) {
        $('html, body')
          .stop()
          .animate({
            scrollTop: $anchor.offset().top - fixedElementHeight,
          }, 200);

      }
    };
    $(window).on('hashchange load', function() {
      adjustAnchor();
    });
  })(jQuery, window);
}

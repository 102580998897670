export default function() {
  const increase = document.querySelectorAll('.increases');
  const decrease = document.querySelectorAll('.decreases');

  function increaseValue(index) {
    let value = document.getElementsByClassName('quantity')[index].value;
    window.InvalidAgesQuantity = false;
    if (!ValidateQuantityInput(index)) {
      const WrapperEl = document.getElementsByClassName('quantity')[index].closest('.step-wrapper').querySelector('.step-warning');
      const messageDependent = WrapperEl.getAttribute('data-msg-dependent');
      const ageName = document.getElementsByClassName('quantity')[index].closest('.type-row').querySelector('.person').textContent;
      WrapperEl.textContent = messageDependent.replace('{ageName}', ageName);
      window.InvalidAgesQuantity = true;
      return;
    }
    value = isNaN(value) ? 0 : value;
    value++;
    document.getElementsByClassName('quantity')[index].value = value;
  }

  function decreaseValue(index) {
    let value = document.getElementsByClassName('quantity')[index].value;
    value = isNaN(value) ? 0 : value;
    value < 1 ? value = 1 : '';
    value--;
    document.getElementsByClassName('quantity')[index].value = value;
  }

  increase.forEach(function (plus, index) {
    plus.addEventListener('click', () => {
      increaseValue(index);
      validateLimit();
    });
  });

  decrease.forEach(function (minor, index) {
    minor.addEventListener('click', () => {
      decreaseValue(index);
      validateLimit();
    });
  });

  const validateLimit = () => {
    const totalValueInputs =  document.querySelectorAll('.steps__step-wrapper .quantity');
    let totalValueQ = 0;
    totalValueInputs.forEach((el) => {
      totalValueQ += parseInt(el.value);
    });
    const warningEl = document.querySelector('.step-wrapper .step-warning');
    const paxLimitMsg = warningEl.getAttribute('data-limit-pax-message');
    if (totalValueQ > 19) {
      warningEl.textContent = paxLimitMsg;
      window.LimitPaxReached = true;
    } else {
      warningEl.textContent = '';
      window.LimitPaxReached = false;
    }
  }
}

export function GetTotalNoDependents() {
  let total = 0;
  const anyValue =  document.querySelectorAll('.quantity[data-dependent="0"]');
  anyValue.forEach((el) => {
    total += parseInt(el.value);
  });
  return total;
}

export function ValidateQuantityInput(index) {
  const el = document.getElementsByClassName('quantity')[index];
  let valid = true;
  if (el.getAttribute('data-dependent') !== '0') {
    valid = GetTotalNoDependents() > 0;
  }
  return valid;
}

export default function() {
  let calendarEl = document.getElementById('calendar');
  let calendarInitOnLoad = true;
  if (calendarEl) {
    if (calendarEl.hasAttribute('data-delay-init')) {
      calendarInitOnLoad = false;
      const initCaller = document.querySelector(calendarEl.getAttribute('data-delay-init'));
      if (initCaller) {
        initCaller.addEventListener('click', () => {
          const preventCalendarInit = typeof window.preventCalendarInit === 'undefined' || window.preventCalendarInit !== false;
          if (!preventCalendarInit) {
            init();
            const infoHeight = initCaller.querySelector('.info-body').scrollHeight;
            initCaller.querySelector('.info-body').setAttribute('style', `height: ${infoHeight}px`)
          }
        });
      }
    }
    if (calendarInitOnLoad) {
      init();
    }
  }
  function init() {
    if (calendarEl) {
      let events = $('section.schedules-and-rates').data('events');
      let initialDate = $('section.schedules-and-rates').data('initial-date');
      let lang = $('section.schedules-and-rates').data('lang');
      const eventsParams =  $(calendarEl).data('events');
      const initialDateParams = $(calendarEl).data('initial-date');
      const langParams = $(calendarEl).data('lang');
      if (eventsParams) {
        events = eventsParams;
      }
      if (initialDateParams) {
        initialDate = initialDateParams;
      }
      if (langParams) {
        lang = langParams;
      }
      let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      if (lang === 'es') {
        monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      }
      if (lang === 'fr') {
        monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
      }
      if (lang === 'ca') {
        monthNames = ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'];
      }
      let validRange = {
        start: (new Date()).getFullYear() + '-03-01',
        end: (new Date()).getFullYear() + '-11-01',
      };
      let visibleRange = validRange;
      let outSeason = false;
      // Check out-season for hide all days from calendar
      /*if ($('section.schedules-and-rates').length > 0) {
        outSeason = $('section.schedules-and-rates').data('out-season');
        if (outSeason) {
          const m = (new Date()).getMonth().toString().padStart(2, '0');
          validRange = {
            start: (new Date()).getFullYear() + '-' + m + '-01',
            end: (new Date()).getFullYear() + '-' + m + '-31',
          };
          visibleRange = {
            start: (new Date()).getFullYear() + '-' + m + '-01',
            end: (new Date()).getFullYear() + '-' + m + '-31',
          };
        }
      }*/
      // eslint-disable-next-line no-undef
      const calendar = new FullCalendar.Calendar(calendarEl, {
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'prev',
          center: 'title',
          end: 'next',
        },
        titleFormat: {
          month: 'long',
        },
        dayHeaderFormat: { weekday: 'short' },
        locale: lang,
        height: 'auto',
        stickyHeaderDates: false,
        weekNumberCalculation: 'ISO',
        selectable: true,
        selectAllow: function(selectInfo) {
          const hours = Math.abs(selectInfo.end - selectInfo.start) / 36e5;
          return hours <= 24;
        },
        events: events,
        validRange: validRange,
        visibleRange: visibleRange,
        dateClick: function(info) {
          if (outSeason) {
            return;
          }
          $('.fc-day').removeClass('selected').removeClass('fc-day-today');
          $(info.dayEl).addClass('selected').addClass('fc-day-today');
          calendar.gotoDate(info.date);
          const seasonNameEl = $('.schedules__text .season-name');
          $('#price-details .season__dot').removeClass('season__dot--promo').removeClass('season__dot--blue').removeClass('season__dot--green');
          $('#price-details .day').text(info.date.getDate());
          $('#price-details .month').text(monthNames[info.date.getMonth()]);
          $('.schedule__resume .day').text(info.date.getDate());
          $('.schedule__resume .month').text(monthNames[info.date.getMonth()]);
          let anyValidSeason = false;
          if ($(info.dayEl).find('.fc-event').hasClass('promo')) {
            anyValidSeason = true;
            seasonNameEl.text(seasonNameEl.data('season-promo'));
            $('#price-details .season__dot').addClass('season__dot--promo');
            $('#price-details .details__price .price__item[data-service=\'TT\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TT\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTB\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TTB\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTD\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TTD\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTE\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TTE\']').text());
            $('#price-details .details__price .price__item[data-service=\'CHD\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'CHD\']').text());
          }
          if ($(info.dayEl).find('.fc-event').hasClass('blue')) {
            anyValidSeason = true;
            seasonNameEl.text(seasonNameEl.data('season-blue'));
            $('#price-details .season__dot').addClass('season__dot--blue');
            $('#price-details .details__price .price__item[data-service=\'TT\'] .price__num').text($('.rates__prices .prices__item.blue[data-service=\'TT\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTB\'] .price__num').text($('.rates__prices .prices__item.blue[data-service=\'TTB\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTD\'] .price__num').text($('.rates__prices .prices__item.blue[data-service=\'TTD\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTE\'] .price__num').text($('.rates__prices .prices__item.blue[data-service=\'TTE\']').text());
            $('#price-details .details__price .price__item[data-service=\'CHD\'] .price__num').text($('.rates__prices .prices__item.blue[data-service=\'CHD\']').text());
          }
          if ($(info.dayEl).find('.fc-event').hasClass('green')) {
            anyValidSeason = true;
            seasonNameEl.text(seasonNameEl.data('season-green'));
            $('#price-details .season__dot').addClass('season__dot--green');
            $('#price-details .details__price .price__item[data-service=\'TT\'] .price__num').text($('.rates__prices .prices__item.green[data-service=\'TT\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTB\'] .price__num').text($('.rates__prices .prices__item.green[data-service=\'TTB\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTD\'] .price__num').text($('.rates__prices .prices__item.green[data-service=\'TTD\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTE\'] .price__num').text($('.rates__prices .prices__item.green[data-service=\'TTE\']').text());
            $('#price-details .details__price .price__item[data-service=\'CHD\'] .price__num').text($('.rates__prices .prices__item.green[data-service=\'CHD\']').text());
          }
          if (!anyValidSeason) {
            $('#price-details .season__dot').addClass('season__dot--promo');$('#price-details .details__price .price__item[data-service=\'TT\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TT\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTB\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TTB\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTD\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TTD\']').text());
            $('#price-details .details__price .price__item[data-service=\'TTE\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'TTE\']').text());
            $('#price-details .details__price .price__item[data-service=\'CHD\'] .price__num').text($('.rates__prices .prices__item.promo[data-service=\'CHD\']').text());
            $('.schedule__hours').html('');
          } else {
            const d = new Date(info.date);
            const monthN = (d.getMonth() + 1).toString().padStart(2, '0');
            const dayN = d.getDate().toString().padStart(2, '0');
            const noRequest = document.querySelector('body.page-template-template-funnel'); // No ajax request if is on funnel template
            if (!noRequest) {
              // eslint-disable-next-line no-undef
              setActivityHours(dayN + '-' + monthN  + '-' +  d.getFullYear());
            }
          }
        },
      });
      calendar.setOption('now', initialDate);
      calendar.render();
      if (outSeason) {
        $('#calendar .fc-day').addClass('fc-day-other');
      }
      window.calendar = calendar;
    }
  }
}
